import { useHistory } from 'react-router-dom'

import { IGoBackProps } from '../interfaces/actions'
import { CinnamonPaths } from '../consts/defaultConfigValues/defaultConstants'
import useAppSelector from './useAppSelector'

const useBack = () => {
  const history = useHistory()
  const goBack = ({ path, state }: IGoBackProps) => {
    if (path) {
      history.push(path, state)
      return
    }
    history.goBack()
  }
  return goBack
}

export const useDynamicBack = () => {
  const history = useHistory()
  const storefrontName = useAppSelector((state) => state.defaultSlice.storeName)
  const sessionID = useAppSelector((state) => state.defaultSlice.sessionId)
  const homePath = `/${storefrontName}/${sessionID}/`
  const goBack = ({ path, state }: IGoBackProps) => {
    if (path) {
      history.push(path, state)
      return
    }
    if (history.length > 1) {
      history.goBack()
      return
    }
    history.push(homePath)
  }

  return goBack
}

export default useBack
