import { datadogRum } from '@datadog/browser-rum'
import { ApolloError } from '@apollo/client'
import { setUserId } from '@snowplow/browser-tracker'
import {
  FIRST_PAGE_NUMBER,
  PRODUCTS_PAGE_SIZE,
} from '../../consts/defaultConfigValues/defaultConstants'
import { useLazyGetSession } from '../../graphQL/queries/getSession'
import { useLazyGetCart } from '../../graphQL/queries/getCart'
import { useLazyGetCatalog } from '../../graphQL/queries/getCatalog'
import { ErrorMessageTranslation, ICart, LoadersType } from '../../interfaces'
import { setBanners } from '../../redux/slices/banners/banners'
import { setCategoryList } from '../../redux/slices/catalog'
import {
  addLoader,
  manageErrors,
  removeLoader,
  setSessionData,
} from '../../redux/slices/config'
import castBanners from '../../utils/castBanners'
import {
  castICatalogItemToICategoryArray,
  filterCategoriesForMenu,
} from '../../utils/categoryUtils'
import { generateDataDogContext } from '../../utils/dataDog'
import { onCartResponse } from '../../utils/onCartResponse'
import useAppDispatch from '../useAppDispatch'
import useAppSelector from '../useAppSelector'
import { useAdvancedFilters } from '../filters/useFilters'
import {
  ISession,
  SessionDataArgs,
} from '../../graphQL/queries/getSession/types'
import { ICatalog, ICatalogItem } from '../../graphQL/queries/getCatalog/types'
import { setMessage, setShow } from '../../redux/slices/globalPromotions'
import { getTotalPromotionsLabel } from '../../utils/promotions/getTotalPromotionsLabel'
import { CartProductResponse, IPromotion } from '../../graphQL/commons'
import { castICatalogItems } from '../../utils/castICatalogItems'

export const useInitialCatalogData = () => {
  const dispatch = useAppDispatch()
  const state = useAppSelector((reduxState) => reduxState)
  const getAdvancedFiltersValues = useAdvancedFilters()

  const globalErrorConfig = {
    errorMessageTranslation: ErrorMessageTranslation.InitialData,
    showErrorTitle: true,
    globalError: true,
  }

  const onCompleted = (
    sessionData: SessionDataArgs,
    catalogData: ICatalogItem[],
    cartData: CartProductResponse,
    totalPromotions: IPromotion[],
  ) => {
    const categories = castICatalogItems(catalogData, state.defaultSlice.config)

    onCartResponse({
      data: cartData,
      categories,
      sessionData,
      dispatch,
      state,
    })
    if (totalPromotions.length > 0) {
      dispatch(
        setMessage(
          getTotalPromotionsLabel(totalPromotions, state.defaultSlice.config),
        ),
      )
      dispatch(setShow(true))
    }
  }

  const onError = (error: ApolloError) => {
    dispatch(manageErrors(globalErrorConfig))
    const context = generateDataDogContext({
      title: 'Get initial catalog data got an error',
      extraInfo: { function: 'useInitialCatalogData > onError' },
    })
    datadogRum.startView(context.viewName)
    datadogRum.addError(error, context)
  }

  const onSessionCompleted = ({ session }: ISession) => {
    const phoneNumber = session?.customer?.phoneNumber?.length
      ? session.customer.phoneNumber[0]
      : ''
    setUserId(phoneNumber)
    dispatch(setSessionData(session))
    dispatch(setBanners(castBanners(session.banners || [])))
    if (
      session.configuration?.filters?.promotions.active ||
      session.configuration?.filters?.categories.active ||
      session.configuration?.filters?.brands.active
    ) {
      getAdvancedFiltersValues()
    }
  }

  const onCatalogCompleted = (catalogResponse: ICatalog) => {
    const filteredCategories = filterCategoriesForMenu(catalogResponse.catalog)
    const sanitizedCategories =
      castICatalogItemToICategoryArray(filteredCategories)

    dispatch(setCategoryList(sanitizedCategories))
  }

  const [getSession] = useLazyGetSession({
    onCompleted: onSessionCompleted,
    onError,
  })

  const [getCart] = useLazyGetCart({
    onError,
  })

  const [getCatalog] = useLazyGetCatalog({
    onCompleted: onCatalogCompleted,
    onError,
    errorPolicy: 'ignore',
  })

  const fetchInitialDataAction = async (
    sessionId: string,
    storeName: string,
  ) => {
    try {
      if (!sessionId) {
        dispatch(manageErrors(globalErrorConfig))
        return
      }
      dispatch(addLoader(LoadersType.INITIAL_DATA))

      await Promise.all([
        getSession({
          variables: {
            storefrontName: storeName,
            sessionUid: sessionId,
          },
        }),
        getCatalog({
          variables: {
            showChildrenCategories: true,
            catalogStorefrontName: storeName,
            catalogSessionId: sessionId,
            totalPromotionsSessionId: sessionId,
            pagination: {
              pageNumber: FIRST_PAGE_NUMBER,
              pageSize: PRODUCTS_PAGE_SIZE,
            },
            groupCategories: true,
          },
        }),
        getCart({
          variables: {
            cartStorefrontName: storeName,
            cartSessionUid: sessionId,
          },
        }),
      ]).then((values) => {
        const [
          { data: sessionData },
          { data: catalogData },
          { data: cartData },
        ] = values

        if (sessionData?.session && catalogData?.catalog && cartData?.cart) {
          onCompleted(
            sessionData.session,
            catalogData.catalog,
            cartData.cart,
            catalogData.getTotalPromotions,
          )
        }
      })
    } catch (exception) {
      const context = generateDataDogContext({
        title: 'could not load initial catalog data',
        extraInfo: { function: 'useInitialCatalogData' },
      })
      datadogRum.startView(context.viewName)
      datadogRum.addError(exception, context)
    } finally {
      dispatch(removeLoader(LoadersType.INITIAL_DATA))
    }
  }

  return fetchInitialDataAction
}

export default useInitialCatalogData
