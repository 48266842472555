import useAppDispatch from './useAppDispatch'
import {
  openModal as openModalAction,
  closeModal as closeModalAction,
} from '../redux/slices/config'
import { IModalActionsProps } from '../interfaces/actions'
import { setSubItem, setSubItems } from '../redux/slices/catalog'
import { isSubItem, isSubItems } from '../utils/isSubItem'

const useModal = () => {
  const dispatch = useAppDispatch()
  const openModal = ({ container, state, item = '' }: IModalActionsProps) => {
    if (
      state?.subItem &&
      isSubItem(state.subItem) &&
      state?.subItems &&
      isSubItems(state?.subItems)
    ) {
      dispatch(setSubItem(state.subItem))
      dispatch(setSubItems(state.subItems))
    }
    dispatch(openModalAction({ container, state, item }))
  }

  const closeModal = ({ container, state }: IModalActionsProps) => {
    dispatch(setSubItem(null))
    dispatch(setSubItems([]))
    dispatch(closeModalAction({ container, state }))
  }

  return { openModal, closeModal }
}

export default useModal
