/* eslint-disable react/jsx-props-no-spreading */
import { useEffect } from 'react'
import { Dialog } from '@engyalo/delivery-ui-components'
import Hotjar from '@hotjar/browser'
import getCinnamonProps from '../hooks/useCinnamonProps'
import type { UnresolvedRecord } from '../interfaces'
import type { ITemplateActions } from '../interfaces/templates'
import { RootState } from '../redux/store'
import { sendGoogleEvent } from '../utils/sendGoogleAnalytics'
import sendSnowplowTracker from '../utils/sendSnowplow'

const DialogComponent = ({
  actions,
  state,
}: {
  actions: ITemplateActions
  state: RootState
}) => {
  const {
    defaultSlice: { dialogs = { show: false, id: '' } },
  } = state
  const { show: open = false, id } = dialogs

  const { show, ...proccessProps } = getCinnamonProps(
    dialogs as UnresolvedRecord,
    state,
    actions,
  )

  useEffect(() => {
    if (proccessProps?.title && open) {
      let action = ''
      if (
        typeof proccessProps?.title === 'object' &&
        !Array.isArray(proccessProps?.title) &&
        proccessProps?.title !== null
      ) {
        action = `${proccessProps?.title?.value} Dialog`
      } else if (typeof proccessProps?.title === 'string') {
        action = `${proccessProps?.title} Dialog`
      } else {
        action = ''
      }
      const category = 'openDialog'
      sendGoogleEvent(category, action)
      Hotjar.event(action)
      sendSnowplowTracker({
        category,
        action,
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id])

  return <Dialog open={open} id={id} {...proccessProps} />
}

export default DialogComponent
