import { Provider } from 'react-redux'
import { ApolloProvider } from '@apollo/client'
import { AppWrapper } from '@engyalo/delivery-ui-components'
import { datadogRum } from '@datadog/browser-rum'
import Hotjar from '@hotjar/browser'
import Routes from './routes/index'
import Splash from './components/Splash'
import AppTheme from './containers/AppThemeConnected'
import apolloClientConfig from './config/apolloClient'
import RouterParams from './components/RouterParams'
import GlobalLoader from './components/GlobalLoader'
import store from './redux/store'

if (process.env.REACT_APP_DATADOG_CLIENT_TOKEN) {
  datadogRum.init({
    applicationId: process.env.REACT_APP_DATADOG_APP_ID,
    clientToken: process.env.REACT_APP_DATADOG_CLIENT_TOKEN,
    env: process.env.REACT_APP_DATADOG_ENV,
    service: process.env.REACT_APP_DATADOG_SERVICE_NAME,
    site: 'datadoghq.com',
    trackInteractions: true,
    premiumSampleRate: 0,
    trackViewsManually: Boolean(
      process.env.REACT_APP_DATADOG_ENABLE_TRACKING_VIEW,
    ),
    defaultPrivacyLevel: 'mask-user-input',
  })
}

Hotjar.init(
  Number(process.env.REACT_APP_HOTJAR_ID),
  Number(process.env.REACT_APP_HOTJAR_VERSION),
)

const App = () => {
  return (
    <Provider store={store}>
      <ApolloProvider client={apolloClientConfig}>
        <AppTheme>
          <AppWrapper>
            <Routes>
              <RouterParams />
            </Routes>
            <Splash />
            <GlobalLoader />
          </AppWrapper>
        </AppTheme>
      </ApolloProvider>
    </Provider>
  )
}

export default App
