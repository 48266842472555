import { IPromotionUiCart, IPromotionUiCartInput } from '../../interfaces'
import { getActivePromotionsFields } from '../common/getPromotionFields'
import formatPromotionMessage, {
  IFormatPromotionMessage,
} from '../formatPromotionMessage'

export const getVolumePromotionUiDetails = ({
  cartProduct,
  activePromotion,
  config,
}: IPromotionUiCartInput): IPromotionUiCart | undefined => {
  const texts = config?.texts?.cartPromotions
  const { discounts, quantity, price } = cartProduct
  const validDiscounts = discounts || 0
  const {
    fields: { customerGetsPercentage, customerGetsCash, type },
    promotion,
  } = getActivePromotionsFields(activePromotion)
  if (customerGetsCash) {
    return undefined
  }

  const percentage = `${((customerGetsPercentage || 0) * 100).toFixed(0)}%`

  const { volumeDefaultLabel, defaultSaving } = texts || {}
  const formatArgs: IFormatPromotionMessage = {
    message: '',
    promotion,
    config,
    saving: validDiscounts,
    amount: validDiscounts,
    placeholders: {
      discount: percentage,
      count: String(quantity),
    },
  }
  const label = formatPromotionMessage({
    ...formatArgs,
    message: volumeDefaultLabel as string,
  })

  const result: IPromotionUiCart = { label, type }

  if (validDiscounts) {
    const value = price - validDiscounts / quantity

    result.saving = formatPromotionMessage({
      ...formatArgs,
      message: defaultSaving as string,
    })

    result.value = value
  }

  return result
}
