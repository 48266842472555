import { newTracker, TrackerConfiguration } from '@snowplow/browser-tracker'
import { SnowplowEcommercePlugin } from '@snowplow/browser-plugin-snowplow-ecommerce'

const snowplowNamespace = process.env.REACT_APP_SNOWPLOW_NAMESPACE as string
const snowplowUrl = process.env.REACT_APP_SNOWPLOW_URL as string
const snowplowPostPath = process.env.REACT_APP_SNOWPLOW_POSTPATH as string
const snowplowAppId = process.env.REACT_APP_SNOWPLOW_APP_ID as string

const extraParams: TrackerConfiguration = {
  plugins: [SnowplowEcommercePlugin()],
  platform: 'web',
  appId: snowplowAppId,
}

if (snowplowPostPath && snowplowPostPath !== '') {
  extraParams.postPath = snowplowPostPath
}
const setupSnowplow = () =>
  newTracker(snowplowNamespace, snowplowUrl, { ...extraParams })

export default setupSnowplow
