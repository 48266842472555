import { ActivePromotion, IPackage } from '../graphQL/commons'
import {
  ICartGroup,
  ICartProduct,
  ICatalogItem,
  IConfig,
  IProduct,
} from '../interfaces'
import { getMultiPromotionUiDetails } from './catalog/getMultiPromotionUiDetails'
import { setPromotionUiDetailsCatalog } from './catalog/setPromotionUiDetailsCatalog'

/**
 * A map object that contains product quantity info.
 * @typedef {(Record<string,QuantityInfo>)} QuantityMapObject
 */
type QuantityMapObject = Record<
  string,
  {
    quantity: number
    promotionalQty: number
    index?: number
    minQtyAllowed?: number
    inCartQuantity?: number
    package?: IPackage | null
  }
>

/**
 * Args for setProductsQuantity function
 * @typedef {(Record<string,QuantityInfo>)} QuantityMapObject
 */
export interface ISetProductsQuantityArgs {
  products?: IProduct[]
  flattedCartItems?: ICartProduct[]
  config?: IConfig
  activePromotions: ActivePromotion[]
  treatAsMultiPromo?: boolean
}

export const setPackagesValues = (
  sku: string,
  extraPackages: IPackage[],
  items: ICartProduct[],
): IPackage[] =>
  extraPackages.map(({ name: packageName, ...data }) => {
    const packageItems = items.filter(
      (item) => item.package?.name === packageName && item.sku === sku,
    )
    return {
      ...data,
      value: packageItems[0]?.quantity || 0,
      name: packageName,
    }
  })

/**
 * Function declaration to flat items from cart and groups
 *
 * @internal
 * @param items - The cart items
 * @param groups - The groups of items
 * @returns Flatted items array
 */
export const getFlattedCartItems = ({
  items = [],
  groups = [],
}: {
  items?: ICartProduct[]
  groups?: ICartGroup[]
}): {
  flattedCartItems: ICartProduct[]
} => {
  if (groups.length) {
    // flat groups int ICartProduct[] as items
    const groupsItemsFlatted: ICartProduct[] = groups.flatMap(
      (item) => item.items,
    )
    return { flattedCartItems: groupsItemsFlatted }
  }
  return { flattedCartItems: items }
}

/**
 * Function declaration to match catalog and cart items to set quantity on each catalog item
 * because headless doesn't provide the quantity on catalog items.
 *
 * @internal
 * @param categories - The categories
 * @param flattedItems - The flatted cart items
 * @returns Categories updated with quantity based on cart/groups
 */
export const setItemsQuantity = ({
  categories = [],
  flattedCartItems = [],
  config,
  activePromotions,
}: {
  categories: ICatalogItem[]
  flattedCartItems: ICartProduct[]
  config?: IConfig
  activePromotions: ActivePromotion[]
}): {
  categories: ICatalogItem[]
} => {
  // quantities object declaration to avoid iterate the array each time
  let quantitiesObj: QuantityMapObject = {}

  // iterate newItems array to get { ... { sku : { quantity, index } } } object
  // to avoid iterate cart/group items to get a quantity for specific product
  flattedCartItems.forEach((product: ICartProduct, index: number) => {
    const { quantity, promotionalQty, minQtyAllowed, inCartQuantity } = product
    quantitiesObj = {
      ...quantitiesObj,
      [product.sku]: {
        quantity,
        promotionalQty,
        index,
        minQtyAllowed,
        inCartQuantity,
        package: product?.package || null,
      },
    }
  })
  // iterate categories object to set quantity to each product
  // based on cart/groups and set promotion ui details
  const newCategories = categories.map(
    (category: ICatalogItem): ICatalogItem => {
      return {
        ...category,
        productsPrices:
          category.productsPrices?.map((product: IProduct): IProduct => {
            // get product quantity from quantitiesObj
            const quantityObj = quantitiesObj[product.sku]
            const isPackage = quantityObj?.package !== null
            let extraPackages: IPackage[] | null = null
            if (isPackage && product.extraPackages) {
              extraPackages = setPackagesValues(
                product.sku,
                product.extraPackages,
                flattedCartItems,
              )
            }
            const productUpdated: IProduct = {
              ...product,
              quantity: quantityObj?.quantity || 0,
              promotionalQty: quantityObj?.promotionalQty || 0,
              minQtyAllowed: quantityObj?.minQtyAllowed || 0,
              inCartQuantity: quantityObj?.inCartQuantity || 0,
              extraPackages,
            }
            const promotion = setPromotionUiDetailsCatalog({
              product: productUpdated,
              config,
              activePromotions,
            })
            return promotion ? { ...productUpdated, promotion } : productUpdated
          }) || [],
      }
    },
  )

  return {
    categories: newCategories,
  }
}

/**
 * Pass a products list and return the same products list but with the quantity of cart updated
 * @param products[]
 * @param flattedCartItems[]
 * @returns products[]
 */
export const setProductsQuantity = ({
  products = [],
  flattedCartItems = [],
  config,
  activePromotions,
  treatAsMultiPromo,
}: ISetProductsQuantityArgs): IProduct[] => {
  return products.map((currentProduct) => {
    const {
      quantity = 0,
      promotionalQty = 0,
      minQtyAllowed = 0,
    } = flattedCartItems.find(({ sku }) => sku === currentProduct.sku) || {}

    const { promotions } = currentProduct
    const isPackage = currentProduct?.package !== null
    let extraPackages: IPackage[] | null = null
    if (isPackage && currentProduct.extraPackages) {
      extraPackages = setPackagesValues(
        currentProduct.sku,
        currentProduct.extraPackages,
        flattedCartItems,
      )
    }
    const productResult: IProduct = {
      ...currentProduct,
      quantity,
      promotionalQty,
      minQtyAllowed,
      extraPackages,
    }

    const isMultiPromo = treatAsMultiPromo && promotions?.length > 1

    if (isMultiPromo && config) {
      productResult.promotionsDetails = getMultiPromotionUiDetails({
        config,
        product: productResult,
      })
    } else {
      const promotion = setPromotionUiDetailsCatalog({
        product: productResult,
        config,
        activePromotions,
      })

      if (promotion) {
        productResult.promotion = promotion
      }
    }

    return productResult
  })
}
