import { trackCheckoutStep } from '@snowplow/browser-plugin-snowplow-ecommerce'
import { validateIsAlcohol, validateIsReturnable } from '../utils/validations'
import useAppDispatch from './useAppDispatch'
import useLoadDialogsValidations from './useLoadDialogValidations'
import { setValidators } from '../redux/slices/config'
import useAppSelector from './useAppSelector'

/**
 * Example script
    {
      name: CinnamonActionNames.PLACE_ORDER,
      args: {
        cart: '{{state.cartSlice}}',
        orderMinAmount:
          '{{state.defaultSlice.sessionData.configuration.checkoutRules.cartConditionPendingMinAmount.quantity}}',
        orderMinQty:
          '{{state.defaultSlice.sessionData.configuration.checkoutRules.cartConditionPendingMinQty.quantity}}',
        categories: '{{state.catalogSlice.categories}}',
        session: '{{state.defaultSlice.sessionData}}',
      },
    }
 */

// eslint-disable-next-line @typescript-eslint/naming-convention
export enum validationsList {
  VALIDATE_ALCOHOL = 'validateAlcohol',
  VALIDATE_RETURNABLE = 'validateReturnable',
}

const useValidatePlaceOrder = () => {
  const dispatch = useAppDispatch()
  const loadModal = useLoadDialogsValidations()

  const cart = useAppSelector((state) => state.cartSlice)
  const session = useAppSelector((state) => state.defaultSlice.sessionData)

  const applyValidations = () => {
    trackCheckoutStep({
      step: 1,
    })
    const checkoutRules = session?.configuration?.checkoutRules
    const validateAlcohol =
      checkoutRules?.cartConditionPendingUserValidation.isActive
    const validateReturnable =
      checkoutRules?.cartWarningCheckReturnables.isActive
    const validations: Array<string> = []
    if (validateAlcohol && validateIsAlcohol(cart)) {
      validations.push(validationsList.VALIDATE_ALCOHOL)
    }
    if (validateReturnable && validateIsReturnable(cart)) {
      validations.push(validationsList.VALIDATE_RETURNABLE)
    }
    // push all the modals validations to defaultSlice.validations
    dispatch(setValidators(validations))
    // executes validations and all the modal required
    return loadModal({ validators: validations })
  }

  return applyValidations
}

export default useValidatePlaceOrder
