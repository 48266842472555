import { CartListProps } from '@engyalo/delivery-ui-components/lib/ui/components/CartList'
import { DefaultProps } from '../../../interfaces'
import {
  CinnamonActionNames,
  CinnamonPaths,
  DefaultDialogsContainerNames,
  TemplateActionNames,
  TextLibrary,
} from '../defaultConstants'
import { ClearCartButton } from './childProps/Button'
import { PriceDefault } from './childProps/Price'
import { ItemCardCart } from './ItemCard'

export const CartListDefault: DefaultProps<CartListProps> = {
  cart: {
    items: '{{cart.items}}' as any,
    groups: '{{cart.groups}}' as any,
    total: '{{cart.total}}' as any,
    subtotal: '{{cart.subtotal}}' as any,
  },
  heading: {
    value: TextLibrary.CART_TITLE,
    buttons: [ClearCartButton],
  },
  price: PriceDefault,
  itemCard: {
    ...ItemCardCart,
    maxQtyMessage: TextLibrary.CART_MAX_QTY_ALERT,
    image: {
      ...ItemCardCart.image,
      dimensions: '{{globals.imageConfig.imageSizes.cartImage}}' as any,
    },
    promotionTag: {
      ...ItemCardCart.promotionTag,
      lines: 1,
    },
    // COM-2889 delete this when headless returns item extra fields on cart promotions
    body: {
      align: 'left',
      fields: [
        'name',
        'promotionTag',
        'promotionSavingLabel',
        'productOptionsList',
      ],
    },
  },
  labelMap: '{{globals.cartLabels}}' as any,
  deleteActions: {
    cinnamonActions: [
      {
        name: CinnamonActionNames.OPEN_DIALOG,
        args: {
          container: DefaultDialogsContainerNames.REMOVE_ITEM,
          id: 'removeItemdialog-0001',
          item: '{{this.data}}',
          icon: {
            name: 'MdWarningAmber',
          },
          title: TextLibrary.CART_REMOVE_TITLE,
          message: TextLibrary.CART_REMOVE_MESSAGE,
          buttons: [
            {
              label: TextLibrary.ACTIONS_ACCEPT,
              actions: {
                cinnamonActions: [
                  {
                    name: TemplateActionNames.CART_DELETE_PRODUCT,
                    args: {
                      product: '{{this.data}}',
                      currentSubItem: '{{this.data.subItem}}',
                    },
                  },
                  {
                    name: CinnamonActionNames.CLOSE_DIALOG,
                  },
                ],
              },
            },
            {
              label: TextLibrary.ACTIONS_CANCEL,
              color: 'inherit',
              variant: 'outlined',
              actions: {
                cinnamonActions: [
                  {
                    name: CinnamonActionNames.CLOSE_DIALOG,
                    args: {
                      container: DefaultDialogsContainerNames.REMOVE_ITEM,
                      item: '{{this.data}}',
                      rejected: true,
                    },
                  },
                ],
              },
            },
          ],
        },
      },
    ],
  } as any,
  emptyMessage: {
    icon: 'MdAddShoppingCart',
    title: TextLibrary.CART_EMPTY_TITLE,
    message: TextLibrary.CART_EMPTY_MESSAGE,
    buttons: [
      {
        label: TextLibrary.CART_BACK_HOME,
        color: 'primary',
        variant: 'text',
        actions: {
          cinnamonActions: [
            {
              name: CinnamonActionNames.GO_BACK,
              args: {
                path: CinnamonPaths.HOME,
              },
            },
          ],
        } as any,
      },
    ],
  },
}
