import { MapProps } from '@engyalo/delivery-ui-components/lib/interfaces/mappedProps'
import { TagsProps } from '@engyalo/delivery-ui-components/lib/ui/components/Tags'
import { TextLibrary } from '../defaultConstants'

export const tagsMap: Record<string, MapProps<TagsProps>> = {
  linkedProducts: {
    props: {
      label: TextLibrary.TAGS_LINKED_PRODUCTS,
      iconImage: '/assets/icons/associated_product.png',
    },
  },
  alcohol: {
    props: {
      label: TextLibrary.TAGS_ALCOHOL,
    },
  },
  returnable: {
    props: {
      label: TextLibrary.TAGS_RETURNABLE,
    },
  },
  stock: {
    show: false,
    props: {
      label: TextLibrary.PRODUCT_STOCK,
    },
    conditionalProps: [
      {
        conditionals: [
          {
            condition: 'isLessThanOrEqual',
            value: 0,
          },
        ],
        show: true,
        props: {
          label: TextLibrary.PRODUCT_WITHOUT_STOCK,
          color: 'error',
        },
      },
      {
        conditionals: [
          {
            condition: 'isLessThanOrEqual',
            value:
              '{{default.sessionData.configuration.stock.threshold.criticalLowWarning.quantity}}',
          },
        ],
        show: '{{default.sessionData.configuration.stock.threshold.criticalLowWarning.enabled}}' as unknown as boolean,
        props: {
          label:
            '{{default.sessionData.configuration.stock.threshold.criticalLowWarning.warningMessage}}',
          color: 'error',
        },
      },
      {
        conditionals: [
          {
            condition: 'isLessThanOrEqual',
            value:
              '{{default.sessionData.configuration.stock.threshold.lowWarning.quantity}}',
          },
        ],
        show: '{{default.sessionData.configuration.stock.threshold.lowWarning.enabled}}' as unknown as boolean,
        props: {
          label:
            '{{default.sessionData.configuration.stock.threshold.lowWarning.warningMessage}}',
        },
      },
    ],
  },
}
