import { useCallback, useRef } from 'react'
import { datadogRum } from '@datadog/browser-rum'
import { ApolloError } from '@apollo/client'
import { trackRemoveFromCart } from '@snowplow/browser-plugin-snowplow-ecommerce'
import Hotjar from '@hotjar/browser'
import {
  SubItems,
  SubItem,
} from '@engyalo/delivery-ui-components/lib/interfaces/catalog'
import { IProductCart } from '../../graphQL/mutations/cartRemoveProduct/types'
import useCartRemoveProduct from '../../graphQL/mutations/cartRemoveProduct'
import useAppDispatch from '../useAppDispatch'
import useAppSelector from '../useAppSelector'
import onRemoveProduct from '../../utils/onRemoveProduct'
import { useGetCart } from './useGetCart'
import { debounceById } from '../../utils/debounce'
import { IGenericFunction, IProduct, TBundle } from '../../interfaces'
import { onCartResponse } from '../../utils/onCartResponse'
import { Referrers } from '../../consts/defaultConfigValues/defaultConstants'

import { generateDataDogContext } from '../../utils/dataDog'
import getAlertErrorMessage, {
  AlertMessageType,
} from '../../utils/getAlertErrorMessage'
import { addAlert } from '../../redux/slices/config/config'
import { sendGoogleEvent } from '../../utils/sendGoogleAnalytics'
import { formatEcommerceAcceleratorData } from '../../utils/sendSnowplow'

const callbackDebounceTime = process.env.REACT_APP_CART_ACTION_DEBOUNCE_TIME
  ? +process.env.REACT_APP_CART_ACTION_DEBOUNCE_TIME
  : 500

export const useDeleteItemFromCart = () => {
  const getCartAction = useGetCart()
  const dispatch = useAppDispatch()
  const abortControllers = useRef<Record<string, AbortController>>({})
  const state = useAppSelector((stateRedux) => stateRedux)
  const {
    defaultSlice: { sessionId = '', storeName = '' },
  } = state
  const { config } = state.defaultSlice
  const category = 'cart'
  let action = 'Remove from cart'

  const onCompleted = (data: IProductCart) => {
    if (data.cartRemoveProduct) {
      onCartResponse({
        data: data.cartRemoveProduct,
        state,
        dispatch,
      })
    }
  }

  const onError = (error: ApolloError) => {
    const message = getAlertErrorMessage(
      error.networkError
        ? AlertMessageType.Network
        : AlertMessageType.RemoveItem,
      config?.texts,
    )
    dispatch(addAlert({ message }))
    getCartAction()
    const context = generateDataDogContext({
      title: 'Remove product mutation error',
      extraInfo: { function: 'useDeleteItemFromCart > onError' },
    })
    datadogRum.startView(context.viewName)
    datadogRum.addError(error, context)
  }

  const [removeProduct] = useCartRemoveProduct({ onCompleted, onError })

  const execRemoveCartMutation = (
    quantity: number,
    sku: string,
    product: IProduct,
    currency: string,
    referrer?: Referrers,
    subItems?: SubItems,
    replace?: boolean,
    packageName?: string,
  ) => {
    const controller = new window.AbortController()
    abortControllers.current[sku] = controller
    if (referrer) {
      action = `Remove from cart from ${referrer}`
    }
    Hotjar.event(action)
    sendGoogleEvent(category, action, sku, quantity)
    const { productCategory, productPrice, totalValue, truncatedCurrency } =
      formatEcommerceAcceleratorData(
        product.category || '',
        product.price || 0,
        currency,
        quantity,
      )
    trackRemoveFromCart({
      products: [
        {
          id: sku,
          name: product.name,
          brand: product.brand || '',
          category: productCategory,
          price: productPrice,
          currency: truncatedCurrency,
        },
      ],
      total_value: totalValue,
      currency: truncatedCurrency,
    })
    window.fbq('trackCustom', 'RemoveFromCart', { sku, quantity })
    onRemoveProduct({
      removeProductFunction: removeProduct,
      sku,
      quantity,
      sessionId,
      storeName,
      referrer,
      signal: controller.signal,
      dispatch,
      subItems,
      replace,
      packageName,
    })
  }

  const callBackRemoveProductToCart = useCallback(
    debounceById(
      execRemoveCartMutation as IGenericFunction,
      callbackDebounceTime,
    ),
    [],
  )

  const isSubItem = (subItem: unknown): subItem is SubItem => {
    const typedObj = subItem as SubItem
    return (
      typeof typedObj.quantity === 'number' &&
      typeof typedObj.items === 'object' &&
      typeof typedObj.hash === 'string' &&
      typedObj.items !== null
    )
  }

  const removeItemAction = ({
    product,
    value,
    newValue,
    currency,
    referrer,
    isBundle,
    newSubItems,
    currentSubItems,
    isPackage,
  }: {
    product: IProduct
    value: number
    newValue: number
    currency: string
    referrer?: Referrers
    isBundle?: boolean
    newSubItems?: SubItem
    currentSubItems?: SubItems
    isPackage?: boolean
  }) => {
    try {
      if (newValue !== value || isBundle) {
        const {
          sku,
          minQtyAllowed = 0,
          name,
          package: productPackage,
        } = product
        const packageNameFromCart = productPackage?.name || undefined
        const packageName = isPackage ? name : packageNameFromCart
        abortControllers.current[sku]?.abort()
        const replaceCart = true
        const handledSubItems: SubItems = currentSubItems || []
        if (isBundle && newSubItems && isSubItem(newSubItems)) {
          const hasPreviousSubItems = !!handledSubItems.length
          const indexOfBundle = hasPreviousSubItems
            ? handledSubItems?.findIndex(
                (item) => item.hash === newSubItems.hash,
              )
            : 0
          if (newValue === 0) {
            handledSubItems.splice(indexOfBundle, 1)
          } else {
            handledSubItems[indexOfBundle] = {
              ...newSubItems,
              items: {
                ...newSubItems.items,
              },
              quantity: newValue,
            }
          }
        }
        const cleanedSubItems = handledSubItems.map(
          ({ hash, ...subItemValue }) => subItemValue,
        )
        const handledValue = isBundle
          ? handledSubItems.reduce((acc, item) => acc + item.quantity, 0)
          : newValue - minQtyAllowed
        callBackRemoveProductToCart(
          sku,
          handledValue,
          sku,
          product,
          currency,
          referrer,
          cleanedSubItems,
          replaceCart,
          packageName,
        )
      }
    } catch (exception) {
      const context = generateDataDogContext({
        title: 'could not remove product from cart',
        extraInfo: {
          product,
          value,
          newValue,
          function: 'removeItemAction',
        },
      })
      datadogRum.startView(context.viewName)
      datadogRum.addError(exception, context)
    }
  }
  return { removeItemAction, execRemoveCartMutation }
}

export default useDeleteItemFromCart
