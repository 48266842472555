import useAppDispatch from '../useAppDispatch'
import { setCurrentBundleCount } from '../../redux/slices/catalog'

const useSetCurrentBundleCount = () => {
  const dispatch = useAppDispatch()
  const setBundleCount = (count: { newValue: number }) => {
    dispatch(setCurrentBundleCount(count.newValue))
  }
  return setBundleCount
}

export default useSetCurrentBundleCount
