/* eslint-disable react/jsx-props-no-spreading, react/require-default-props */
import { FC, useEffect } from 'react'
import ReactGA from 'react-ga4'
import { useParams } from 'react-router-dom'
import { trackPageViewWithCustomerEntity } from '../utils/sendSnowplow'
import { TemplateActionNames } from '../consts/defaultConfigValues/defaultConstants'
import { ITemplate, ITemplateActions } from '../interfaces/templates'
import Default from './Default'
import {
  useClearFilters,
  useFetchFilterResults,
  useSetFilterCategory,
  useSetFilters,
} from '../hooks/filters/useFilters'
import { IGenericFunction } from '../interfaces'
import useBannersClick from '../hooks/useBannersClick'
import { useAppSelector } from '../hooks'

const Filters: FC<ITemplate> = ({ ...props }) => {
  const { getFilterResults } = useFetchFilterResults()
  const setFilterCategory = useSetFilterCategory()
  const setFilters = useSetFilters()
  const clearFilters = useClearFilters()
  const bannersClick = useBannersClick()
  const { slug = '' } = useParams<{ slug: string }>()
  const { sessionData } = useAppSelector((state) => state.defaultSlice)
  const customer = sessionData?.customer
  const workflow = sessionData?.workflow
  const { id: sessionId = '' } = sessionData || {}
  const { code = '', phoneNumber = [], type = '' } = customer || {}
  const { userUid, channelUid, channel, workflowName } = workflow || {}
  const templateActions: ITemplateActions = {
    [TemplateActionNames.FILTERS_CLEAR_FILTERS]: clearFilters,
    [TemplateActionNames.FILTERS_GET_FILTERS_RESULTS]: getFilterResults,
    [TemplateActionNames.FILTERS_SET_FILTERS]: setFilters as IGenericFunction,
    [TemplateActionNames.FILTERS_SET_FILTER_CATEGORY]:
      setFilterCategory as IGenericFunction,
    [TemplateActionNames.CATALOG_BANNERS_CLICK]:
      bannersClick as IGenericFunction,
  }
  useEffect(() => {
    trackPageViewWithCustomerEntity(props.container.uid, {
      code,
      phoneNumber: phoneNumber[0] || '',
      type,
      userUid,
      channelUid,
      channel,
      sessionId,
      workflowName,
      storefrontName: slug,
    })
    ReactGA.send({ hitType: 'pageview', page: `/${props.container.uid}` })
    window.fbq('track', 'PageView', { page: `/${props.container.uid}` })
  }, [])
  return <Default templateActions={templateActions} {...props} />
}

export default Filters
