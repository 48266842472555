import { ViewObserverProps } from '@engyalo/delivery-ui-components/lib/ui/components/ViewObserver'
import { DefaultProps } from '../../../interfaces'
import deepMerge from '../../../utils/deepMerge'
import { TemplateActionNames } from '../defaultConstants'

export const ViewObserverDefault: DefaultProps<ViewObserverProps> = {
  showLoader: true,
  offset: 20,
}

export const ViewObserverCategoryNav = deepMerge(ViewObserverDefault, {
  loading: '{{categoriesNav.fetchingMore}}' as any,
  showLoader: '{{categoriesNav.category.productsPrices.isNotEmpty()}}' as any,
  entryActions: {
    cinnamonActions: [
      {
        name: TemplateActionNames.CATEGORIES_NAVIGATION_GET_PRODUCTS,
      },
    ],
  } as any,
})
