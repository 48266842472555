import { IBundleExtraType, IGroup, Item } from '../graphQL/commons'
import { ICartGroup, ICartProduct, IConfig } from '../interfaces'
import { sanitizeBundleExtra } from './sanitizeBundleExtra'

/**
 * Cast an Item to ICartProduct
 * @param source: Item[]..
 * @internal
 */
const castItemToICartProduct = ({ jumpQty, ...rest }: Item): ICartProduct => {
  return {
    ...rest,
    step: jumpQty || undefined,
  }
}

/**
 * Cast array Item to array ICartProduct
 * @param source: Item[]..
 * @internal
 */
export const castItemsToICartProducts = (items: Item[]): ICartProduct[] =>
  items.map(castItemToICartProduct)

/**
 * Cast IGroup to ICartGroup
 * @param source: IGroup[]..
 * @internal
 */
export const castGroupToICartGroup = ({
  items,
  ...group
}: IGroup): ICartGroup => {
  return {
    ...group,
    items: castItemsToICartProducts(items),
  }
}

/**
 * Cast array IGroup to array ICartGroup
 * @param source: Item[]..
 * @internal
 */
export const castIGroupsToICartGroups = (groups: IGroup[]): ICartGroup[] =>
  groups.map(castGroupToICartGroup)

/**
 * Cast an Item array to it's equivalent ICartProduct array
 * @param source: Item[]
 * @param autoAdd: boolean
 * @param disableFreeGoodsAsDiscount : boolean
 * @param config : IConfig
 * @internal
 */
export const sanitizeCartProducts = (
  source: Item[],
  autoAdd: boolean,
  disableFreeGoodsAsDiscount: boolean,
  config?: IConfig,
): ICartProduct[] => {
  const freegoodsAsSubitem = !!config?.options?.freegoodsAsSubitem

  return source.map((item) => {
    const itemAsICartProduct = castItemToICartProduct(item)
    const {
      quantity,
      promotionalQty,
      discounts = 0,
      price,
    } = itemAsICartProduct
    const newQuantity = freegoodsAsSubitem
      ? quantity - promotionalQty
      : quantity
    const minQtyAllowed = autoAdd && !freegoodsAsSubitem ? promotionalQty : 0
    const newDiscounts =
      !freegoodsAsSubitem || disableFreeGoodsAsDiscount
        ? discounts
        : discounts - promotionalQty * price

    const result: ICartProduct = {
      ...itemAsICartProduct,
      quantity: newQuantity,
      inCartQuantity: quantity,
      minQtyAllowed,
      discounts: newDiscounts,
      bundlesExtra:
        itemAsICartProduct.bundlesExtra?.map((bundleExtra) => {
          return sanitizeBundleExtra({
            bundleExtra: bundleExtra as IBundleExtraType,
            bundleConfig: itemAsICartProduct.bundle,
            config,
          })
        }) || null,
    }

    return result
  })
}

/**
 * Cast an IGroup array to it's equivalent ICartGroup array
 * @param source: IGroup[]
 * @param autoAdd: boolean
 * @internal
 */
export const sanitizeCartGroups = (
  source: IGroup[],
  autoAdd: boolean,
  disableFreeGoodsAsDiscount: boolean,
  config?: IConfig,
): ICartGroup[] => {
  return source.map(({ groupBy, items, total, subtotal = 0 }) => {
    return {
      groupBy,
      total,
      subtotal,
      items: sanitizeCartProducts(
        items,
        autoAdd,
        disableFreeGoodsAsDiscount,
        config,
      ),
      visibleTotal: config?.options?.cartGroupingTotals ? total : subtotal,
    }
  })
}
