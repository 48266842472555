import { useEffect, useRef } from 'react'
import { useLazyGetCatalog } from '../../graphQL/queries/getCatalog'
import { useAppDispatch, useAppSelector } from '..'
import noop from '../../utils/noop'
import {
  fetchProducts,
  fetchProductsResponse,
  fetchProductsResponseError,
} from '../../utils/fetchProducts'
import { ICatalog } from '../../graphQL/queries/getCatalog/types'
import { castICatalogItems } from '../../utils/castICatalogItems'

const useCategoryLazyLoad = (element = 'CatalogueDefault-body') => {
  const dispatch = useAppDispatch()
  const sessionId = useAppSelector(
    (state) => state.defaultSlice.sessionId || '',
  )
  const storeName = useAppSelector(
    (state) => state.defaultSlice.storeName || '',
  )
  const config = useAppSelector((state) => state.defaultSlice.config)
  const existingCategories = useAppSelector(
    (state) => state.catalogSlice.categories,
  )
  const pagination = useAppSelector((state) => state.catalogSlice.pagination)
  const cartItems = useAppSelector((state) => state.cartSlice.items)
  const groups = useAppSelector((state) => state.cartSlice.groups)
  const activePromotions = useAppSelector(
    (state) => state.cartSlice.activePromotions,
  )
  const currentCategory = useAppSelector(
    (state) => state.catalogSlice.currentCategory,
  )

  const categorySlug = useAppSelector(
    (state) => state.catalogSlice.currentCategory?.slug || '',
  )

  const onCompleted = (data: ICatalog) => {
    const categoriesResponse = castICatalogItems(data.catalog, config)
    fetchProductsResponse({
      categoriesResponse,
      currentCategory,
      dispatch,
      config,
      existingCategories,
      cartItems,
      groups,
      activePromotions,
    })
  }

  const [getCatalog] = useLazyGetCatalog({
    onError: fetchProductsResponseError,
    onCompleted,
  })

  const observerRef = useRef<IntersectionObserver>()

  useEffect(() => {
    const options = {
      root: document.querySelector(element),
      rootMargin: '0px 0px 5px 0px',
      threshold: 0.5,
    }
    observerRef.current = new IntersectionObserver((event) => {
      const [entries] = event
      const { hasMore = false } = pagination[categorySlug] || {}
      if (!entries.isIntersecting || !hasMore) {
        return
      }
      fetchProducts({
        storeName,
        sessionId,
        pagination,
        currentCategory,
        dispatch,
        getCatalog,
      })
    }, options)

    const selector = `[data-category-name="${currentCategory?.category}"]`
    const target = document.querySelector(selector)

    if (!target) {
      return noop
    }

    observerRef.current.observe(target)

    return () => {
      observerRef.current?.unobserve(target)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    currentCategory?.category,
    categorySlug,
    pagination,
    element,
    dispatch,
    getCatalog,
  ])
}

export default useCategoryLazyLoad
