import { ActivePromotion, PromotionType } from '../../graphQL/commons'
import {
  IConditionAction,
  IConfig,
  IPromotion,
  ITextLibrary,
} from '../../interfaces'
import { getPromotionFields } from '../common/getPromotionFields'
import formatPromotionMessage, {
  IFormatPromotionMessage,
} from '../formatPromotionMessage'

const getLabelByType = (
  isPercentageDiscount: boolean,
  isProductDiscount: boolean,
  texts: ITextLibrary,
) => {
  const {
    globalPromotionPercentage,
    globalPromotionProduct,
    globalPromotionAmount,
  } = texts.cartPromotions

  if (isPercentageDiscount) {
    return globalPromotionPercentage
  }
  if (isProductDiscount) {
    return globalPromotionProduct
  }
  // return cash discount label by default
  return globalPromotionAmount
}

type GlobalPromotionCartInfo = [show: boolean, message?: string]

const conditions: IConditionAction<
  {
    config?: IConfig
    globalActivePromotion: ActivePromotion | undefined
  },
  GlobalPromotionCartInfo
>[] = [
  {
    condition: ({ globalActivePromotion }) => !!globalActivePromotion?.label,
    transformAction: ({ globalActivePromotion }) => [
      true,
      globalActivePromotion!.label!,
    ],
  },
  {
    condition: ({ globalActivePromotion, config }) =>
      !!globalActivePromotion && !!config?.texts,
    transformAction: ({ globalActivePromotion, config }) => {
      const { promotion } = globalActivePromotion!

      const {
        customerGetsCash: cash,
        customerGetsPercentage: percentage,
        customerGetsQuantity: quantity,
        itemsExtra,
      } = getPromotionFields(promotion)

      const itemExtra = itemsExtra?.[0]

      const formatArgs: IFormatPromotionMessage = {
        message: '',
        promotion: promotion as IPromotion,
        config,
        placeholders: {
          product: String(itemExtra?.name || ''),
          quantity: String(quantity),
          discount: cash || `${Number(percentage) * 100}%`,
        },
      }
      return [
        !!globalActivePromotion,
        formatPromotionMessage({
          ...formatArgs,
          message: getLabelByType(
            !!percentage,
            !!quantity && !!itemExtra,
            config!.texts,
          ),
        }),
      ]
    },
  },
]

export const getGlobalPromotionCartInfo: (
  activePromotions: ActivePromotion[],
  config?: IConfig,
) => GlobalPromotionCartInfo = (activePromotions, config) => {
  const globalActivePromotion = activePromotions.find(
    ({ promotion }) => promotion.type === PromotionType.TOTAL,
  )

  return (
    conditions
      .find(({ condition }) => condition({ globalActivePromotion, config }))
      ?.transformAction({ globalActivePromotion, config }) || [false]
  )
}
