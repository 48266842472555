import useModal from './useModal'
import useDialog from './useDialog'
import useBack, { useDynamicBack } from './useBack'
import usePushView, { useConditionalPushView } from './usePushView'
import useSearch from './useSearch'
import useProductDetail from './useProductDetail'
import {
  useAddItemToCart,
  useDeleteItemFromCart,
  useLogAction,
  useCreateOrder,
  useLoadDialogValidations,
} from '.'
import type { UnresolvedRecord } from '../interfaces'
import useBundlesHandler from './catalog/useBundlesHandler'
import useSetCurrentBundleCount from './catalog/useSetCurrentBundleCount'
import { useChosenFreeGoods } from './catalog/useChosenFreegoods'

const useCinnamonActions = (): UnresolvedRecord => {
  const goBack = useBack()
  const goBackDynamic = useDynamicBack()
  const pushView = usePushView()
  const conditionalPushView = useConditionalPushView()
  const { search, clearSearch } = useSearch()
  const productDetail = useProductDetail()
  const { openModal, closeModal } = useModal()
  const { openDialog, closeDialog } = useDialog()
  const { addItemAction: addItemToCart } = useAddItemToCart()
  const { removeItemAction: deleteItemFromCart } = useDeleteItemFromCart()
  const logAction = useLogAction()
  const createOrder = useCreateOrder()
  const loadDialogsValidations = useLoadDialogValidations()
  const bundlesHandler = useBundlesHandler()
  const setCurrentBundleCount = useSetCurrentBundleCount()
  const { chosenFreegoods, confirmChosenFreegoods } = useChosenFreeGoods()

  return {
    goBack,
    goBackDynamic,
    pushView,
    conditionalPushView,
    search,
    clearSearch,
    productDetail,
    openModal,
    closeModal,
    addItemToCart,
    logAction,
    deleteItemFromCart,
    createOrder,
    loadDialogsValidations,
    openDialog,
    closeDialog,
    bundlesHandler,
    setCurrentBundleCount,
    chosenFreegoods,
    confirmChosenFreegoods,
  }
}

export default useCinnamonActions
