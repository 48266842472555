/* eslint-disable react/jsx-props-no-spreading, react/require-default-props */
import { FC, useEffect } from 'react'
import ReactGA from 'react-ga4'
import { useParams } from 'react-router-dom'
import { trackPageViewWithCustomerEntity } from '../utils/sendSnowplow'
import { useRecommendedProductsActions, useAppSelector } from '../hooks'
import { TemplateActionNames } from '../consts/defaultConfigValues/defaultConstants'
import {
  useClearCart,
  useDeleteProductFromCart,
  usePlaceOrderFlow,
} from '../hooks/cart'
import { ITemplate, ITemplateActions } from '../interfaces/templates'
import Default from './Default'
import { IGenericFunction } from '../interfaces'
import getAnalyticsItems from '../utils/getAnalyticsItems'
import { getAnalyticsCartPayload } from '../utils/getAnalyticsPayload'
import useValidateLinkedProducts from '../hooks/linkedProducts/useValidateLinkedProducts'

const Cart: FC<ITemplate> = ({ ...props }) => {
  const clearCartAction = useClearCart()
  const deleteProduct = useDeleteProductFromCart()
  const { config, sessionData } = useAppSelector((state) => state.defaultSlice)
  const cart = useAppSelector((state) => state.cartSlice)
  const customer = sessionData?.customer
  const workflow = sessionData?.workflow
  const { id: sessionId = '' } = sessionData || {}
  const { code = '', phoneNumber = [], type = '' } = customer || {}
  const { userUid, channelUid, channel, workflowName } = workflow || {}
  const { closeRecommendedProducts, updateRecommendedButton } =
    useRecommendedProductsActions()

  const { placeOrderFlow, placeOrderFlowNext, placeOrderFlowClear } =
    usePlaceOrderFlow()

  const { validateLinkedProducts } = useValidateLinkedProducts()
  const { slug = '' } = useParams<{ slug: string }>()

  const templateActions: ITemplateActions = {
    [TemplateActionNames.CART_CLEAR_CART]: clearCartAction,
    [TemplateActionNames.CART_DELETE_PRODUCT]:
      deleteProduct as IGenericFunction,
    [TemplateActionNames.CART_CLOSE_RECOMMENDED_PRODUCTS_MODAL]:
      closeRecommendedProducts as IGenericFunction,
    [TemplateActionNames.CART_UPDATE_RECOMMENDED_PRODUCT_BUTTON]:
      updateRecommendedButton as IGenericFunction,
    [TemplateActionNames.PLACE_ORDER_FLOW]: placeOrderFlow as IGenericFunction,
    [TemplateActionNames.PLACE_ORDER_FLOW_NEXT]:
      placeOrderFlowNext as IGenericFunction,
    [TemplateActionNames.PLACE_ORDER_CLEAR]:
      placeOrderFlowClear as IGenericFunction,
    [TemplateActionNames.LINKED_PRODUCTS_VALIDATE_STOCK]:
      validateLinkedProducts as IGenericFunction,
  }
  useEffect(() => {
    const { pathname } = window.location
    const route = pathname.split('/').pop()
    if (route === 'cart') {
      const cartAnalyticsItems = getAnalyticsItems(cart)
      const analyticsPayload = getAnalyticsCartPayload(
        cart,
        cartAnalyticsItems,
        config,
      )
      trackPageViewWithCustomerEntity(props.container.uid, {
        code,
        phoneNumber: phoneNumber[0] || '',
        type,
        userUid,
        channelUid,
        channel,
        sessionId,
        workflowName,
        storefrontName: slug,
      })
      ReactGA.event('view_cart', analyticsPayload)
      window.fbq('trackCustom', 'ViewCart', {
        ...analyticsPayload,
        currency: config?.options?.currencyPrefix,
      })
    }
  }, [])
  return <Default templateActions={templateActions} {...props} />
}

export default Cart
