import useAppDispatch from '../useAppDispatch'
import { TBundle } from '../../interfaces'
import {
  setCurrentBundle,
  setCurrentBundlePrice,
} from '../../redux/slices/catalog'
import useAppSelector from '../useAppSelector'
import formatPrice from '../../utils/formatPrice'

const useBundlesHandler = () => {
  const dispatch = useAppDispatch()
  const selectedProduct = useAppSelector(
    (state) => state.catalogSlice.selectedProduct,
  )
  const config = useAppSelector((state) => state.defaultSlice.config)

  const bundlesHandler = ({
    selections,
    completed,
  }: {
    selections: TBundle[]
    completed: boolean
  }) => {
    const validBundlesExtra = selectedProduct?.bundlesExtra || []
    const hasDynamicPrice = !!selectedProduct?.hasDynamicPrice

    if (hasDynamicPrice) {
      const bundleTotal = validBundlesExtra.reduce<number>(
        (acc, { sku, price }) => {
          const validSku = sku || ''
          const selectionResult = selections.find(
            (selection) => !!selection[validSku],
          )
          const validSelectionQuantity = selectionResult?.[validSku] || 0
          const validPrice = price || 0
          return validPrice * validSelectionQuantity + acc
        },
        0,
      )

      const currency: string | undefined =
        config?.options?.currency || config?.options?.currencySymbol
      const format: string | undefined = config?.options?.format

      const formattedBundlePrice = formatPrice({
        currency,
        format,
        price: bundleTotal,
      })

      dispatch(setCurrentBundlePrice(` (${formattedBundlePrice})`))
    }

    dispatch(setCurrentBundle({ selections, completed }))
  }
  return bundlesHandler
}

export default useBundlesHandler
