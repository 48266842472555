import { ButtonProps } from '@engyalo/delivery-ui-components/lib/ui/components/Button'
import { DefaultProps } from '../../../interfaces'
import deepMerge from '../../../utils/deepMerge'
import {
  CinnamonActionNames,
  DefaultModalContainerNames,
  TemplateActionNames,
  TextLibrary,
} from '../defaultConstants'

export const ButtonDefault: DefaultProps<ButtonProps> = {}

export const ButtonFiltersCancel = deepMerge(ButtonDefault, {
  label: TextLibrary.FILTERS_MODAL_BUTTON_CANCEL,
  styles: {
    minWidth: '128px',
    background: '#FFFFFF',
    border: '1px solid #BABABA',
    color: '#2C2C2C',
    ':hover': {
      background: '#FFFFFF',
      border: '1px solid #BABABA',
      color: '#2C2C2C',
    },
  },
  onClick: {
    cinnamonActions: [
      {
        name: CinnamonActionNames.CLOSE_MODAL,
        args: {
          container: DefaultModalContainerNames.FILTERS_CLEAR_CONFIRM,
        },
      },
    ],
  } as any,
})

export const ButtonFiltersClear = deepMerge(ButtonDefault, {
  label: TextLibrary.FILTERS_MODAL_BUTTON_OK,
  styles: {
    minWidth: '128px',
  },
  onClick: {
    cinnamonActions: [
      {
        name: TemplateActionNames.FILTERS_CLEAR_FILTERS,
      },
      {
        name: CinnamonActionNames.CLOSE_MODAL,
        args: {
          container: DefaultModalContainerNames.FILTERS_CLEAR_CONFIRM,
        },
      },
    ],
  } as any,
})
