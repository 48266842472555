import { ItemCardProps } from '@engyalo/delivery-ui-components/lib/ui/components/ItemCard'
import { DefaultProps } from '../../../interfaces'
import deepMerge from '../../../utils/deepMerge'
import {
  CounterForList,
  CounterDefaultArgs,
  CounterLinkedProducts,
  CounterRecommended,
  CounterForListBundle,
  CounterPackageArgs,
} from './childProps/Counter'
import {
  CinnamonActionNames,
  CinnamonPaths,
  DefaultModalContainerNames,
  TemplateActionNames,
  TextLibrary,
} from '../defaultConstants'
import { PriceDefault } from './childProps/Price'
import { PromotionType } from '../../../graphQL/commons'

export const ItemCardDefault: DefaultProps<ItemCardProps> = {
  price: PriceDefault,
  tags: {
    items: '{{globals.tags.items}}' as any,
    map: {
      template: '{{globals.tags.map}}' as any,
    },
  },
  sideContent: {
    items: '{{globals.sideContent.items}}' as any,
    map: {
      template: '{{globals.sideContent.map}}' as any,
    },
  },
  image: {
    src: '{{config.options.placeholderImage}}',
    resizeEnabled: '{{globals.imageConfig.resizeEnabled}}' as any,
    resizeServiceUrl: '{{env.REACT_APP_RESIZER_SERVICE_URL}}' as any,
  },
  promotionDetail: {
    styles: {
      '& .Text': {
        textAlign: 'left',
        fontSize: '12px',
      },
    },
  },
  packagePriceMap: '{{globals.price.packagePriceMap}}' as any,
}

export const ItemCardProduct = deepMerge(ItemCardDefault, {
  counter: CounterForList,
  actions: {
    cinnamonActions: [
      {
        name: CinnamonActionNames.PUSH_VIEW,
        args: {
          path: CinnamonPaths.SKU_RELATIVE,
        },
      },
      {
        name: CinnamonActionNames.OPEN_MODAL,
        args: {
          container: DefaultModalContainerNames.PRODUCT_DETAIL,
          state: {
            item: '{{this.data}}',
          },
        },
      },
      {
        name: CinnamonActionNames.GET_PRODUCT_DETAIL,
        args: {
          sku: '{{this.data.sku}}',
        },
      },
    ],
  } as any,
  footer: {
    ...ItemCardDefault.footer,
    bundleTexts: {
      label: TextLibrary.BUNDLE_LIST_TITLE,
      selectButtonLabel: TextLibrary.BUNDLE_LIST_SELECT_BUTTON_LABEL,
      descriptionForCheckBox: TextLibrary.BUNDLE_LIST_DESCRIPTION_CHECKBOX,
      descriptionForCounters: TextLibrary.BUNDLE_LIST_DESCRIPTION_COUNTERS,
      descriptionForRadio: TextLibrary.BUNDLE_LIST_DESCRIPTION_RADIO,
    },
    packageTexts: {
      listLabel: TextLibrary.PACKAGE_LIST_TITLE,
      description: TextLibrary.PACKAGE_LIST_DESCRIPTION,
      selectButtonLabel: TextLibrary.BUNDLE_LIST_SELECT_BUTTON_LABEL,
      productNoAvailable: TextLibrary.PACKAGE_LIST_OUT_OFF_STOCK,
    },
  },
})

export const ItemCardCatalog = deepMerge(ItemCardProduct, {
  fieldsToDisplay: '{{globals.catalog.fieldsToDisplay}}' as any,
})

export const ItemCardCart = deepMerge(ItemCardProduct, {
  counterBundle: CounterForListBundle,
  actions: {
    cinnamonActions: [
      {
        name: CinnamonActionNames.PUSH_VIEW,
        args: {
          path: CinnamonPaths.SKU_RELATIVE,
        },
      },
      {
        name: CinnamonActionNames.OPEN_MODAL,
        args: {
          container: DefaultModalContainerNames.PRODUCT_DETAIL,
          state: {
            item: '{{this.data}}',
            subItem: '{{this.data.subItem}}',
            subItems: '{{this.data.subItemsProduct}}',
          },
        },
      },
      {
        name: CinnamonActionNames.GET_PRODUCT_DETAIL,
        args: {
          sku: '{{this.data.sku}}',
          fromCart: true,
        },
      },
    ],
  } as any,
  sideContent: {
    items: ['item.attributes', 'deleteButton'],
  },
})

export const ItemCardProductDetailModal = deepMerge(ItemCardDefault, {
  'data-testid': 'product-detail',
  item: '{{catalog.selectedProduct}}' as any,
  layout: 'detail',
  elevation: 0,
  image: {
    ...ItemCardDefault.image,
    dimensions: '{{globals.imageConfig.imageSizes.productDetailImage}}' as any,
  },
  padding: 16,
  body: {
    gap: 4,
  },
  footer: {
    bundleTexts: {
      label: TextLibrary.BUNDLE_LIST_TITLE,
      descriptionForCheckBox: TextLibrary.BUNDLE_LIST_DESCRIPTION_CHECKBOX,
      descriptionForCounters: TextLibrary.BUNDLE_LIST_DESCRIPTION_COUNTERS,
      descriptionForRadio: TextLibrary.BUNDLE_LIST_DESCRIPTION_RADIO,
    },
    packageTexts: {
      listLabel: TextLibrary.PACKAGE_LIST_TITLE,
      description: TextLibrary.PACKAGE_LIST_DESCRIPTION,
      selectButtonLabel: TextLibrary.BUNDLE_LIST_SELECT_BUTTON_LABEL,
      productNoAvailable: TextLibrary.PACKAGE_LIST_OUT_OFF_STOCK,
    },
    fields: [
      'promotionDetail',
      'promotionsDetails',
      'productOptions',
      'productPackages',
    ],
    bundlesHandler: {
      cinnamonActions: [
        {
          name: CinnamonActionNames.BUNDLES_HANDLER,
          args: {
            selections: '{{this.selections}}',
            completed: '{{this.completed}}',
            item: '{{this.item}}',
          },
        },
      ],
    } as any,
    packagesProps: {
      addToCart: {
        cinnamonActions: [
          {
            name: CinnamonActionNames.ADD_ITEM_TO_CART,
            args: CounterPackageArgs,
          },
        ],
      } as any,
      removeFromCart: {
        cinnamonActions: [
          {
            name: CinnamonActionNames.REMOVE_ITEM_FROM_CART,
            args: CounterPackageArgs,
          },
        ],
      } as any,
      disabledActions: {
        cinnamonActions: [
          {
            name: CinnamonActionNames.OPEN_DIALOG,
            args: {
              icon: {
                name: 'MdCheck',
              },
              title: TextLibrary.PRODUCT_STOCK_TITLE,
              message: TextLibrary.PRODUCT_STOCK_MESSAGE,
              buttons: [
                {
                  label: TextLibrary.ACTIONS_ACCEPT,
                  actions: {
                    cinnamonActions: [
                      {
                        name: CinnamonActionNames.CLOSE_DIALOG,
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      } as any,
    },
  },
  standalone: true,
  sideContent: {
    items: ['backButton'],
    map: {
      backButton: {
        type: 'iconButton',
        area: 'top',
        side: 'end',
        float: true,
        props: {
          icon: 'MdExpandMore',
          actions: {
            cinnamonActions: [
              {
                name: CinnamonActionNames.GO_BACK,
              },
              {
                name: CinnamonActionNames.CLOSE_MODAL,
                args: {
                  container: DefaultModalContainerNames.PRODUCT_DETAIL,
                },
              },
            ],
          },
        },
      },
    },
  },
})

export const ItemCardProductDetailPage = deepMerge(ItemCardProductDetailModal, {
  sideContent: undefined,
  runOnLoad: {
    cinnamonActions: [
      {
        name: CinnamonActionNames.GET_PRODUCT_DETAIL,
        args: {
          sku: '{{params.sku}}',
          promotionsTypesToExclude: [PromotionType.COMBO, PromotionType.VOLUME],
        },
      },
    ],
  },
})

export const ItemCardProductRecommended = deepMerge(ItemCardProduct, {
  name: {
    lines: 1,
  },
  counter: CounterRecommended,
  sideContent: undefined,
  actions: {
    cinnamonActions: [
      {
        name: CinnamonActionNames.PUSH_VIEW,
        args: {
          path: CinnamonPaths.SKU_RELATIVE,
        },
      },
      {
        name: CinnamonActionNames.OPEN_MODAL,
        args: {
          container: DefaultModalContainerNames.RECOMMENDED_PRODUCT_DETAIL,
          state: {
            item: '{{this.data}}',
          },
        },
      },
      {
        name: CinnamonActionNames.GET_PRODUCT_DETAIL,
        args: {
          sku: '{{this.data.sku}}',
        },
      },
    ],
  } as any,
})

export const ItemCardProductRecommendedDetail = deepMerge(
  ItemCardProductDetailModal,
  {
    'data-testid': 'recommended-product-detail',
    sideContent: {
      items: ['closeButton'],
      map: {
        closeButton: {
          type: 'iconButton',
          area: 'top',
          side: 'start',
          float: true,
          props: {
            icon: 'MdClose',
            size: 'small',
            actions: {
              cinnamonActions: [
                {
                  name: CinnamonActionNames.GO_BACK,
                },
                {
                  name: CinnamonActionNames.CLOSE_MODAL,
                  args: {
                    container:
                      DefaultModalContainerNames.RECOMMENDED_PRODUCT_DETAIL,
                  },
                },
              ],
            },
          },
        },
      },
    },
  },
)

export const ItemCardPrefillCart = deepMerge(ItemCardProductRecommended, {
  actions: undefined,
  image: {
    dimensions:
      '{{globals.imageConfig.imageSizes.frequentlyBoughtTogetherImage}}' as any,
  },
  counter: {
    plusButton: {
      actions: {
        cinnamonActions: [
          {
            name: TemplateActionNames.PREFILL_CART_UPDATE_ITEM,
            args: {
              ...CounterDefaultArgs,
            },
          },
        ],
      } as any,
    },
    minusButton: {
      actions: {
        cinnamonActions: [
          {
            name: TemplateActionNames.PREFILL_CART_UPDATE_ITEM,
            args: {
              ...CounterDefaultArgs,
            },
          },
        ],
      } as any,
    },
  },
})

export const ItemCardLinkedProducts = deepMerge(ItemCardProductRecommended, {
  actions: undefined,
  counter: CounterLinkedProducts,
  tags: {
    items: ['item.stock', 'item.unitsPerPackage', 'item.unitDivision'],
    map: {
      stock: {
        show: true,
        conditionalProps: undefined,
      },
    },
  },
})
