import * as MainHeaderProps from './defaultProps/MainHeader'
import * as MainFooterProps from './defaultProps/MainFooter'
import * as NavMenuProps from './defaultProps/NavMenu'
import * as ItemCardProps from './defaultProps/ItemCard'
import * as ItemListProps from './defaultProps/ItemList'
import * as CatalogueListProps from './defaultProps/CatalogueList'
import * as CategoryListProps from './defaultProps/CategoryList'
import * as CartListProps from './defaultProps/CartList'
import * as AlertProps from './defaultProps/Alert'
import * as MinAmountProps from './defaultProps/MinAmount'
import * as SearchBarProps from './defaultProps/SearchBar'
import * as ViewObserverProps from './defaultProps/ViewObserver'
import * as AdvancedFiltersProps from './defaultProps/AdvancedFilters'
import * as TextProps from './defaultProps/Text'
import * as ButtonProps from './defaultProps/Button'
import * as CarouselProps from './defaultProps/Carousel'
import * as HeaderListProps from './defaultProps/HeaderList'
import * as SummaryListProps from './defaultProps/SummaryList'
import * as OptionsListProps from './defaultProps/OptionsList'
import * as CategoriesListProps from './defaultProps/CategoriesList'

const DEFAULT_COMPONENTS_PROPS = {
  ...NavMenuProps,
  ...MainHeaderProps,
  ...MainFooterProps,
  ...ItemCardProps,
  ...ItemListProps,
  ...CatalogueListProps,
  ...CategoryListProps,
  ...CartListProps,
  ...AlertProps,
  ...MinAmountProps,
  ...SearchBarProps,
  ...ViewObserverProps,
  ...AdvancedFiltersProps,
  ...TextProps,
  ...ButtonProps,
  ...CarouselProps,
  ...HeaderListProps,
  ...SummaryListProps,
  ...OptionsListProps,
  ...CategoriesListProps,
}

export default DEFAULT_COMPONENTS_PROPS
