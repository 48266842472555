/* eslint-disable react/jsx-props-no-spreading, react/require-default-props */
import { FC, useEffect } from 'react'
import ReactGA from 'react-ga4'
import { useParams } from 'react-router-dom'
import { trackPageViewWithCustomerEntity } from '../utils/sendSnowplow'
import { useAppSelector } from '../hooks'
import { TemplateActionNames } from '../consts/defaultConfigValues/defaultConstants'
import { ITemplate, ITemplateActions } from '../interfaces/templates'
import Default from './Default'
import { useCategoriesNavigation } from '../hooks/categoriesnav/useCategoriesNavigation'
import { IGenericFunction } from '../interfaces'

const CategoriesNav: FC<ITemplate> = ({ ...props }) => {
  const { sessionData } = useAppSelector((state) => state.defaultSlice)
  const customer = sessionData?.customer
  const workflow = sessionData?.workflow
  const { id: sessionId = '' } = sessionData || {}
  const { code = '', phoneNumber = [], type = '' } = customer || {}
  const { userUid, channelUid, channel, workflowName } = workflow || {}
  const { slug = '' } = useParams<{ slug: string }>()
  const { setCategoriesNavigation, navigateToCategory } =
    useCategoriesNavigation()

  const templateActions: ITemplateActions = {
    [TemplateActionNames.CATEGORIES_NAVIGATION_SET_CATEGORIES]:
      setCategoriesNavigation as IGenericFunction,
    [TemplateActionNames.CATEGORIES_NAVIGATION_NAVIGATE_TO_CATEGORY]:
      navigateToCategory as IGenericFunction,
  }
  useEffect(() => {
    trackPageViewWithCustomerEntity(props.container.uid, {
      code,
      phoneNumber: phoneNumber[0] || '',
      type,
      userUid,
      channelUid,
      channel,
      sessionId,
      workflowName,
      storefrontName: slug,
    })
    ReactGA.send({ hitType: 'pageview', page: `/${props.container.uid}` })
    window.fbq('track', 'PageView', { page: `/${props.container.uid}` })
  }, [])
  return <Default templateActions={templateActions} {...props} />
}

export default CategoriesNav
