import Hotjar from '@hotjar/browser'
import { DefaultModalContainerNames } from '../consts/defaultConfigValues/defaultConstants'
import { BannerData } from '../interfaces/banners'
import { sanitizeSlug } from '../utils/sanitize'
import { sendGoogleEvent } from '../utils/sendGoogleAnalytics'
import sendSnowplowTracker from '../utils/sendSnowplow'
import useCategoryFilters from './catalog/useCategoryFilters'
import useModal from './useModal'
import useProductDetail from './useProductDetail'
import usePushView from './usePushView'
import { URL_PREFFIX_CATEGORY } from './categoriesnav/constants'
import useAppSelector from './useAppSelector'

const useBannersClick = () => {
  const productDetail = useProductDetail()
  const { openModal } = useModal()
  const pushView = usePushView()
  const { scrollToCategory } = useCategoryFilters()
  const storefrontName = useAppSelector((state) => state.defaultSlice.storeName)
  const sessionID = useAppSelector((state) => state.defaultSlice.sessionId)
  const bannersClick = ({
    data: { type, reference, id },
    navigateToCategory,
  }: {
    data: BannerData
    navigateToCategory: boolean
  }) => {
    const categoryScrollName = 'categoryBannerScroll'
    const categoryNavName = 'categoryBannerNavigation'
    const productNavName = 'productBannerNavigation'
    let action = ''
    switch (type) {
      case 'PRODUCT':
        action = 'Banner redirect to Product'
        Hotjar.event(action)
        sendGoogleEvent(
          productNavName,
          action,
          `${reference}, Banner ID: ${id}`,
        )
        sendSnowplowTracker({
          category: productNavName,
          action,
          label: reference,
          property: `Banner ID: ${id}`,
        })
        window.fbq('trackCustom', 'BannerRedirectToProduct', { sku: reference })
        pushView({ path: `${window.location.pathname}?sku=${reference}` })
        productDetail({ sku: reference })
        openModal({
          container: DefaultModalContainerNames.PRODUCT_DETAIL,
          item: reference,
        })
        break
      case 'CATEGORY':
        if (navigateToCategory) {
          // This is needed because home is replaced in CM.
          // If you're having issues, change home route in default config to match below
          action = 'Banner redirect to Category'
          Hotjar.event(action)
          sendGoogleEvent(
            categoryNavName,
            action,
            `${sanitizeSlug(reference)}, Banner ID: ${id}`,
          )
          sendSnowplowTracker({
            category: categoryNavName,
            action,
            label: sanitizeSlug(reference),
            property: `Banner ID: ${id}`,
          })
          window.fbq('trackCustom', 'BannerRedirectToCategory', {
            category: sanitizeSlug(reference),
          })
          const homePath = `/${storefrontName}/${sessionID}/`
          pushView({
            path: `${homePath}${URL_PREFFIX_CATEGORY}${reference}`,
          })
        } else {
          action = 'Banner scroll to Category'
          Hotjar.event(action)
          sendGoogleEvent(
            categoryScrollName,
            action,
            `${sanitizeSlug(reference)}, Banner ID: ${id}`,
          )
          sendSnowplowTracker({
            category: categoryScrollName,
            action,
            label: sanitizeSlug(reference),
            property: `Banner ID: ${id}`,
          })
          scrollToCategory({ category: sanitizeSlug(reference) })
        }
        break
      case 'GENERAL':
      default:
        break
    }
  }

  return bannersClick
}

export default useBannersClick
