import { ITextLibrary, Localization } from '../../../interfaces'
import en from './en.json'
import es from './es.json'
import pt from './pt.json'

const defaultTexts: Record<Localization, ITextLibrary> = {
  [Localization.En]: en,
  [Localization.Es]: es,
  [Localization.Pt]: pt,
  [Localization.EnUs]: en,
  [Localization.EsMx]: es,
  [Localization.PtBr]: pt,
}

export default defaultTexts
