import { ApolloError, LazyQueryExecFunction } from '@apollo/client'
import { datadogRum } from '@datadog/browser-rum'
import { ItemStatus } from '@engyalo/delivery-ui-components/lib/interfaces/enums'
import { AnyAction, ThunkDispatch } from '@reduxjs/toolkit'
import {
  IProductsPricedPerCollectionVariables,
  ICatalog as ICatalogCommerceSDK,
} from '../graphQL/queries/getCatalog/types'
import { PRODUCTS_PAGE_SIZE } from '../consts/defaultConfigValues/defaultConstants'
import {
  ICartGroup,
  ICartProduct,
  ICatalogItem,
  ICategoryPagination,
  IConfig,
} from '../interfaces'
import {
  setCategories,
  setCategorySelectedStatus,
  setFetchingMoreLoading,
  setNewPagination,
  setPaginationAsCompleted,
} from '../redux/slices/catalog'
import { RootState } from '../redux/store'
import { generateDataDogContext } from './dataDog'
import updateCurrentCategory from './updateCurrentCategory'
import { getFlattedCartItems, setItemsQuantity } from './utilsForCatalogReducer'
import { ActivePromotion } from '../graphQL/commons'

export interface IFetchProductsResponseArgs {
  categoriesResponse: ICatalogItem[]
  currentCategory?: ICatalogItem
  dispatch: ThunkDispatch<RootState, undefined, AnyAction>
  existingCategories: ICatalogItem[]
  cartItems: ICartProduct[]
  groups?: ICartGroup[]
  config?: IConfig
  activePromotions: ActivePromotion[]
}

export interface IFetchProductsArgs {
  storeName: string
  sessionId: string
  pagination: {
    [category: string]: ICategoryPagination
  }
  currentCategory?: ICatalogItem
  dispatch: ThunkDispatch<RootState, undefined, AnyAction>
  getCatalog: LazyQueryExecFunction<
    ICatalogCommerceSDK,
    IProductsPricedPerCollectionVariables
  >
}

export const fetchProducts = async ({
  storeName,
  sessionId,
  pagination,
  currentCategory,
  dispatch,
  getCatalog,
}: IFetchProductsArgs) => {
  try {
    if (pagination[currentCategory?.slug || ''].hasMore) {
      const { pageNumber } = pagination[currentCategory?.slug || '']

      if (!currentCategory?.productsPrices?.length) {
        dispatch(setCategorySelectedStatus(ItemStatus.LOADING))
      }
      dispatch(setFetchingMoreLoading(true))

      await getCatalog({
        variables: {
          groupCategories: true,
          catalogStorefrontName: storeName,
          catalogSessionId: sessionId,
          totalPromotionsSessionId: sessionId,
          categoryName: currentCategory?.category || '',
          pagination: {
            pageNumber: pageNumber + 1,
            pageSize: PRODUCTS_PAGE_SIZE,
          },
        },
      })
    }
  } catch (error) {
    const context = generateDataDogContext({
      title: 'could not fetch products',
      extraInfo: { function: 'fetchProducts' },
    })
    datadogRum.startView(context.viewName)
    datadogRum.addError(error, context)
  } finally {
    dispatch(setCategorySelectedStatus(ItemStatus.OK))
    dispatch(setFetchingMoreLoading(false))
  }
}

export const fetchProductsResponse = ({
  categoriesResponse,
  currentCategory,
  dispatch,
  existingCategories: categories,
  cartItems,
  groups,
  config,
  activePromotions,
}: IFetchProductsResponseArgs) => {
  const [category] = categoriesResponse
  const hasLoadedAllItems =
    (category?.productsPrices?.length || 0) < PRODUCTS_PAGE_SIZE

  if (hasLoadedAllItems) dispatch(setPaginationAsCompleted())

  dispatch(setNewPagination())

  const newCategories = categories.map((categoryToMap: ICatalogItem) => {
    if (categoryToMap.category !== category?.category) {
      return categoryToMap
    }
    if (!categoryToMap.productsPrices) {
      return categoryToMap
    }
    const [cat] = categoriesResponse
    if (cat && cat.productsPrices) {
      // Doing this to avoid duplicated items in the category
      const items = cat?.productsPrices.filter(
        (product) =>
          !categoryToMap?.productsPrices?.some(
            (productinCatalog) => product.sku === productinCatalog.sku,
          ),
      )
      return {
        ...categoryToMap,
        productsPrices: categoryToMap.productsPrices.concat(items),
      }
    }
    return categoryToMap
  })

  const { flattedCartItems } = getFlattedCartItems({
    items: cartItems,
    groups: groups ?? [],
  })

  const { categories: updatedCategories } = setItemsQuantity({
    categories: newCategories,
    flattedCartItems,
    config,
    activePromotions,
  })

  dispatch(setCategories(updatedCategories))
  updateCurrentCategory({
    categories: updatedCategories,
    currentCategory,
    dispatch,
  })
}

export const fetchProductsResponseError = (error: ApolloError) => {
  const context = generateDataDogContext({
    title: 'Fetch products response is an error',
    extraInfo: { function: 'fetchProductsResponseError' },
  })
  datadogRum.startView(context.viewName)
  datadogRum.addError(error, context)
}
