import { IGlobals } from '../../interfaces'
import { PREFILL_CART_LIMIT, TemplateActionNames } from './defaultConstants'
import { cartLabels } from './defaultGlobals/cartLabels'
import { sideContentMap } from './defaultGlobals/sideContent'
import { tagsMap } from './defaultGlobals/tags'

const globals: IGlobals = {
  navMenu: 'tabs',
  counter: {
    step: 1,
    activeInput: true,
    max: null,
    modifierMap: {},
    allowRetryWhenOutOfStock: false,
  },
  catalog: {
    layout: 'default',
    fieldsToDisplay: {},
  },
  summaryList: {
    fieldsToDisplay: [
      'name',
      'quantity',
      'description',
      'unitPrice',
      'totalFee',
      'promotion',
    ],
    summaryFieldsToDisplay: [
      'subtotal',
      'discounts',
      'taxes',
      'perceptions',
      'administrativeServices',
    ],
    summaryAlerts: {
      summaryAlertOnMinAmount: {
        value: null,
      },
    },
  },
  price: {
    packagePriceMap: {},
  },
  unitPrice: {
    showUnitPrice: false,
    unitsInline: false,
  },
  minAmount: {
    showAmount: false,
    showIndicator: true,
  },
  tags: {
    items: ['item.linkedProducts', 'item.attributes', 'item.stock'],
    map: tagsMap,
  },
  sideContent: {
    items: ['item.attributes'],
    map: sideContentMap,
  },
  imageConfig: {
    resizeEnabled: false,
    imageSizes: {
      cartImage: '50x50',
      catalogueImage: '250x250',
      suggestedItemImage: '100x100',
      productDetailImage: '500x500',
      searchImage: '100x100',
      filterImage: '100x100',
      frequentlyBoughtTogetherImage: '250x250',
    },
  },
  cartLabels,
  recommendedProducts: {
    allRecommendations: 100,
    widgetHome: 5,
  },
  prefillCart: {
    createOrder: false,
    limit: PREFILL_CART_LIMIT,
  },
  placeOrderFlow: {
    actions: [
      TemplateActionNames.CART_GET_FREQUENTLY_BOUGHT_TOGETHER,
      TemplateActionNames.LINKED_PRODUCTS_GET_ITEMS,
      TemplateActionNames.CART_PLACE_ORDER,
    ],
    pushAfter: '',
  },
  footer: {
    priceDisplay: '{{symbol}}{{price}}{{code}}',
    feeDisplay: '+ {{symbol}}{{price}}{{code}}',
  },
}

export default globals
