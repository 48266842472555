import {
  trackStructEvent,
  trackPageView,
  addGlobalContexts,
  clearGlobalContexts,
} from '@snowplow/browser-tracker'
import { ICartProduct } from '../interfaces/cart'

interface ITrackStructEvent {
  // The name you supply for the group of objects you want to track e.g. 'media', 'ecomm'
  category: string
  // A string which defines the type of user interaction for the web object e.g. 'play-video', 'add-to-basket'
  action: string
  // An optional string which identifies the specific object being actioned e.g. ID of the video being played, or the SKU or the product added-to-basket
  label?: string
  // An optional string describing the object or the action performed on it. This might be the quantity of an item added to basket
  property?: string
  // An optional umber to quantify or further describe the user action. This might be the price of an item added-to-basket, or the starting time of the video where play was just pressed
  value?: number
}

interface ITrackPaeViewWithCustomerEntity {
  code: string
  phoneNumber: string
  storefrontName: string
  sessionId: string
  userUid?: string
  channelUid?: string
  channel?: string
  workflowName?: string
  type?: string
}

const sendSnowplowTracker = (params: ITrackStructEvent) => {
  const { category, action, label, property, value } = params
  trackStructEvent({ category, action, label, property, value })
}

const commerceSchemaURL = process.env
  .REACT_APP_SNOWPLOW_COMMERCE_SCHEMA_URL as string
const yaloSchema = process.env.REACT_APP_SNOWPLOW_YALO_SCHEMA_URL as string

const trackPageViewWithCustomerEntity = (
  title: string,
  data: ITrackPaeViewWithCustomerEntity,
) => {
  const {
    code,
    phoneNumber,
    storefrontName,
    userUid,
    channelUid,
    channel,
    sessionId,
    workflowName,
    type,
  } = data
  const contexts = [
    {
      schema: commerceSchemaURL,
      data: {
        customer_code: code,
        customer_phoneNumber: phoneNumber || userUid || 'withOutPhoneNumber',
        customer_type: type || 'withOutCustomerType',
        storefront_name: storefrontName,
        session_id: sessionId,
        workflow_user_uid: userUid || phoneNumber || 'withOutUserId',
        workflow_channel_uid: channelUid || 'withOutChannelUid',
        workflow_channel: channel || 'withOutChannel',
        workflow_name: workflowName || 'withOutWorkflowName',
      },
    },
    {
      schema: yaloSchema,
      data: {
        account_id: code,
        user_id: userUid,
        is_test: 'false',
      },
    },
  ]
  clearGlobalContexts()
  addGlobalContexts(contexts)
  trackPageView({
    title,
  })
}

const formatEcommerceAcceleratorData = (
  category: string,
  price: number,
  currency: string,
  quantity: number,
) => {
  const productCategory: string = category || 'withOutCategory'
  const productPrice: number = +price.toFixed(2) || 0
  const totalValue: number = +(productPrice * quantity).toFixed(2) || 0
  const truncatedCurrency = currency || 'withOutCurrency'
  return { productCategory, productPrice, totalValue, truncatedCurrency }
}

const formatProductDataForTransaction = (
  { sku, name, price }: ICartProduct,
  currency?: string,
) => {
  const productPrice: number = +price.toFixed(2) || 0
  return {
    id: sku,
    name,
    price: productPrice,
    currency: currency || 'withOutCurrency',
    category: 'withOutCategory',
  }
}

export {
  trackPageViewWithCustomerEntity,
  formatEcommerceAcceleratorData,
  formatProductDataForTransaction,
}

export default sendSnowplowTracker
