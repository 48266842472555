import 'react-app-polyfill/ie9'
import 'react-app-polyfill/stable'
import 'regenerator-runtime/runtime'
import 'core-js/features/string/replace-all'
/* eslint-disable import/no-extraneous-dependencies */
import { trackPageView } from '@snowplow/browser-tracker'
import React from 'react'
import ReactDOM from 'react-dom'
import './index.css'
import { install } from 'resize-observer'
import store from './redux/store'
import App from './App'
import 'swiper/swiper-bundle.css'
import 'swiper/swiper.min.css'
import reportWebVitals from './reportWebVitals'
import setupSnowplow from './services/snowplow/setup'

if (!window.ResizeObserver) {
  install()
}

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('root'),
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
setupSnowplow()
